import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.regexp.replace";

var CryptoJS = require("crypto-js");

var encryptByAES = function encryptByAES(message, key) {
  var keyHex = CryptoJS.enc.Utf8.parse(key);
  var encrypted = CryptoJS.AES.encrypt(message, keyHex, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  });
  return encrypted.ciphertext.toString(CryptoJS.enc.Base64).replace(/[\r\n]/g, '');
};

var decryptByAES = function decryptByAES(ciphertext, key) {
  var keyHex = CryptoJS.enc.Utf8.parse(key);
  var decrypted = CryptoJS.AES.decrypt({
    ciphertext: CryptoJS.enc.Base64.parse(ciphertext.replace(/[\r\n]/g, ''))
  }, keyHex, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  });
  return decrypted.toString(CryptoJS.enc.Utf8);
};

var encryptKey = function encryptKey(key) {
  var array = key.split('');
  var letters = 'abcdefghijklmnopqrstuvwxyz0123456789';
  var encryptedKey = '';

  for (var i = 0; i < array.length; i++) {
    encryptedKey += array[i];

    for (var j = 0; j < i % 2 + 1; j++) {
      encryptedKey += letters.substr(parseInt(Math.random() * letters.length), 1);
    }
  }

  return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encryptedKey.split('').reverse().join('')));
};

var decryptKey = function decryptKey(encryptedKey) {
  encryptedKey = CryptoJS.enc.Base64.parse(encryptedKey).toString(CryptoJS.enc.Utf8).split('').reverse().join('');
  var str = '';

  for (var i = 0, j = 0; i < encryptedKey.length; i++) {
    str += encryptedKey[i];
    i += j++ % 2 + 1;
  }

  return str;
};

export { encryptByAES, decryptByAES, encryptKey, decryptKey };