import "/work/project/aihuz/litigant-ui/node_modules/core-js/modules/es6.array.iterator.js";
import "/work/project/aihuz/litigant-ui/node_modules/core-js/modules/es6.promise.js";
import "/work/project/aihuz/litigant-ui/node_modules/core-js/modules/es6.object.assign.js";
import "/work/project/aihuz/litigant-ui/node_modules/core-js/modules/es7.promise.finally.js";
import Vue from "vue";
import "normalize.css/normalize.css"; // A modern alternative to CSS resets

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import zhLocale from "element-ui/lib/locale/lang/zh-CN";
import echarts from "echarts";
import "@/styles/index.scss"; // global css

import App from "./App";
import store from "./store";
import router from "./router";
import "@/icons"; // icon

import "@/permission"; // permission control

import VueSignaturePad from "vue-signature-pad";
/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online! ! !
 */

Vue.prototype.$echarts = echarts;
Vue.prototype.$tablePageSizes = [10, 20, 50, 100, 200];
Vue.prototype.$tableHeaderColor = "rgba(247, 248, 250)"; // set ElementUI lang to EN

Vue.use(ElementUI, {
  zhLocale: zhLocale
});
Vue.use(VueSignaturePad);
Vue.config.productionTip = false;
new Vue({
  el: "#app",
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
});