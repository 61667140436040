import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);
/* Layout */

import Layout from "@/layout";
/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */

export var constantRoutes = [{
  path: "/login",
  name: "login",
  component: function component() {
    return import("@/views/login/index");
  },
  hidden: true
}, {
  path: "/register",
  name: "register",
  component: function component() {
    return import("@/views/login/register");
  },
  hidden: true
}, {
  path: "/certify",
  name: "certify",
  component: function component() {
    return import("@/views/login/certify");
  },
  hidden: true
}, {
  path: "/certifying",
  name: "certifying",
  component: function component() {
    return import("@/views/login/certifying");
  },
  hidden: true
}, {
  path: "/protocol",
  name: "protocol",
  component: function component() {
    return import("@/views/login/protocol");
  },
  hidden: true
}, {
  path: "/404",
  component: function component() {
    return import("@/views/404");
  },
  hidden: true
}, // {
//   path: '/',
//   component: Layout,
//   redirect: '/Survey',
//   children: [{
//     path: 'Survey',
//     name: 'Survey',
//     component: () => import('@/views/Survey/index'),
//     meta: { title: '概况', icon: 'dashboard' }
//   }]
// },
{
  path: "/",
  component: Layout,
  redirect: "/inquiry",
  // name: 'case',
  meta: {
    title: "我的案件",
    icon: "example",
    noCache: false
  },
  children: [{
    path: "inquiry",
    name: "inquiry",
    component: function component() {
      return import("@/views/case/inquiry/index");
    },
    meta: {
      title: "我的案件",
      noCache: false,
      icon: "tree"
    }
  }, {
    path: "/inquiryDetail/:caseId",
    // component: Layout,
    component: function component() {
      return import("@/views/case/inquiry/detail");
    },
    hidden: true,
    name: "inquiryDetail",
    meta: {
      title: "案件详情"
    }
  } // {
  //   path: "/trial/record",
  //   // component: Layout,
  //   component: () => import("@/views/trial/record/index"),
  //   hidden: false,
  //   name: "trialrecord",
  //   meta: {
  //     title: "视频庭审记录",
  //     noCache: false,
  //     icon: "tree"
  //   }
  // },
  // {
  //   path: "/trial/videoTrial",
  //   // component: Layout,
  //   component: () => import("@/views/trial/videoTrial/index"),
  //   hidden: true,
  //   name: "videoTrial",
  //   meta: { title: "视频庭审" }
  // }
  //
  // {
  //   path: 'clericalInquiry',
  //   name: 'clericalInquiry',
  //   component: () => import('@/views/case/clericalInquiry/index'),
  //   meta: { title: '文书查询' }
  // },
  // {
  //   path: 'courtInquiry',
  //   name: 'courtInquiry',
  //   component: () => import('@/views/case/courtInquiry/index'),
  //   meta: { title: '开庭查询' }
  // },
  // {
  //   path: '/handingDetail/:caseId',
  //   // component: Layout,
  //   component: () => import('@/views/case/handing/detail'),
  //   hidden: true,
  //   name: 'handingDetail',
  //   meta: { title: '立案审核详情' },
  // },
  ]
}, {
  path: "/trial/record",
  component: Layout,
  redirect: "/trial/record",
  name: "trialrecord",
  meta: {
    title: "视频庭审记录",
    icon: "tree",
    noCache: false
  },
  children: [{
    path: "/trial/record",
    name: "trialrecord",
    component: function component() {
      return import("@/views/trial/record/index");
    },
    meta: {
      title: "视频庭审记录",
      noCache: false
    }
  }]
}, {
  path: "/trial/videoTrial",
  component: Layout,
  redirect: "/trial/videoTrial",
  name: "videoTrial",
  meta: {
    title: "视频庭审",
    icon: "example",
    noCache: false
  },
  hidden: true,
  children: [{
    path: "/trial/videoTrial",
    name: "videoTrial",
    component: function component() {
      return import("@/views/trial/videoTrial/index");
    },
    meta: {
      title: "视频庭审",
      noCache: false
    }
  }]
}, {
  path: "/personal",
  component: Layout,
  redirect: "/personal/index",
  name: "personal",
  meta: {
    title: "个人资料",
    icon: "example",
    noCache: false
  },
  children: [{
    path: "personal",
    name: "personal",
    component: function component() {
      return import("@/views/personal/index");
    },
    meta: {
      title: "我的资料",
      noCache: false
    }
  }]
}, // 404 page must be placed at the end !!!
{
  path: "*",
  redirect: "/404",
  hidden: true
}];

var createRouter = function createRouter() {
  return new Router({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};

var router = createRouter(); // Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465

export function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
export default router;