module.exports = {
  title: process.env.VUE_APP_BASE_API_TITLE,
  logo: process.env.VUE_APP_BASE_API_LOGO,
  commId: process.env.VUE_APP_BASE_API_COMMID,
  // title:'互仲链仲裁平台',
  // logo:'https://aihuz-open-file.oss-cn-shenzhen.aliyuncs.com/logo/11124545878750041logo.png',
  // commId:"",

  /**
   * @type {boolean} true | false
   * @description Whether fix the header
   */
  fixedHeader: false,

  /**
   * @type {boolean} true | false
   * @description Whether show the logo in sidebar
   */
  sidebarLogo: true
};