import "core-js/modules/es6.function.name";
import { registerByCase, logout as _logout, getInfo } from '@/api/user';
import { getToken, setToken, removeToken } from '@/utils/auth';
import { resetRouter } from '@/router';
import defaultSettings from '@/settings';
var state = {
  token: localStorage.getItem('token'),
  name: localStorage.getItem('name'),
  avatar: '我来了'
};
var mutations = {
  SET_TOKEN: function SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_NAME: function SET_NAME(state, name) {
    state.name = name;
  },
  SET_AVATAR: function SET_AVATAR(state, avatar) {
    state.avatar = avatar;
  }
};
var actions = {
  // user login
  login: function login(_ref, userInfo) {
    var commit = _ref.commit;
    var phone = userInfo.phone,
        code = userInfo.code,
        idCard = userInfo.idCard;
    return new Promise(function (resolve, reject) {
      registerByCase({
        idCard: idCard,
        code: code,
        phone: phone,
        commId: defaultSettings.commId
      }).then(function (response) {
        if (response.code == 1) {
          var data = response.data;
          commit('SET_TOKEN', data.token);
          commit('SET_NAME', data.userName);
          setToken(data.token);
          localStorage.setItem('token', data.token);
          localStorage.setItem('name', data.userName); // localStorage.setItem("userType", data.userType);
          // localStorage.setItem("systemState", data.systemState);

          if (data.commissionInfoDto) {
            localStorage.setItem('litigantTitle', data.commissionInfoDto.name);
            localStorage.setItem('litigantLogo', data.commissionInfoDto.logo);
          } else {
            localStorage.setItem('litigantTitle', defaultSettings.title);
            localStorage.setItem('litigantLogo', '');
          }

          resolve(data);
        } else {
          reject(response);
        }
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // get user info
  //   getInfo({ commit, state }) {
  //     return new Promise((resolve, reject) => {
  //       getInfo(state.token).then(response => {
  //         const { data } = response
  //
  //         if (!data) {
  //           reject('Verification failed, please Login again.')
  //         }
  //
  //         const { name, avatar } = data
  //
  //         commit('SET_NAME', name)
  //         commit('SET_AVATAR', avatar)
  //         resolve(data)
  //       }).catch(error => {
  //         reject(error)
  //       })
  //     })
  //   },
  // user logout
  logout: function logout(_ref2) {
    var commit = _ref2.commit,
        state = _ref2.state;
    return new Promise(function (resolve, reject) {
      _logout(state.token).then(function () {
        commit('SET_TOKEN', '');
        removeToken();
        resetRouter();
        localStorage.setItem('token', '');
        localStorage.setItem('name', ''); // localStorage.setItem("userType", data.userType);
        // localStorage.setItem("systemState", data.systemState);

        localStorage.setItem('title', '');
        localStorage.setItem('logo', '');
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // remove token
  resetToken: function resetToken(_ref3) {
    var commit = _ref3.commit;
    return new Promise(function (resolve) {
      commit('SET_TOKEN', '');
      commit('SET_NAME', '');
      removeToken();
      resolve();
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};