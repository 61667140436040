//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import defaultSettings from '@/settings';
export default {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data: function data() {
    return {
      defaultTitle: defaultSettings.title,
      title: localStorage.getItem('title'),
      // logo:localStorage.getItem('logo'),
      logo: defaultSettings.logo
    };
  }
};